import { CONTENT_ROUTE_TO_STORE_KEY, ROUTE_TO_TYPE, TYPE_TO_ACTIVITY_KEY, TYPE_TO_ROUTE } from './constants';

const FEEDBACK_ITEMS = ['like', 'dislike'];
const SAVED_ITEMS = 'saved';

export const getFeedbackAndFavoritesFromAction = payload => {
  return payload.reduce(
    (acc, item) => {
      const { activityStatusKey, activityStatusOrder, key } = item;

      if (FEEDBACK_ITEMS.includes(activityStatusKey) && key) {
        acc.feedback[key] = item;

        return acc;
      }

      if (activityStatusKey === SAVED_ITEMS && key) {
        acc.savedItems[key] = item;

        return acc;
      }

      const prevStatusOrder = acc.contentStatuses[key]?.activityStatusOrder;

      if (!acc.contentStatuses[key] || prevStatusOrder < activityStatusOrder) {
        acc.contentStatuses[key] = {
          key: activityStatusKey,
          order: activityStatusOrder
        };

        return acc;
      }

      return acc;
    },
    { feedback: {}, savedItems: {}, contentStatuses: {} }
  );
};

export const getAdditionalDataByContentType = ({ contentType, key, slug, id, title }) => {
  switch (contentType) {
    case TYPE_TO_ACTIVITY_KEY.article:
    case TYPE_TO_ACTIVITY_KEY.blog:
      return {
        wpId: id,
        name: title,
        wpSlug: slug,
        key
      };
    default:
      return { key, slug };
  }
};

export const pickAdditionalDataByContentTypeFromStore = ({ contentType, slug, store = {} }) => {
  const contentTypeEnum = ROUTE_TO_TYPE[contentType];
  const storeKey = CONTENT_ROUTE_TO_STORE_KEY[contentTypeEnum];

  if (!store[storeKey][slug]) {
    return { slug };
  }

  switch (contentType) {
    case TYPE_TO_ROUTE.article:
    case TYPE_TO_ROUTE.blog:
      const selectedPost = store.contentPages[slug];

      return {
        wpId: selectedPost.id,
        name: selectedPost.title,
        wpSlug: selectedPost.slug,
        key: selectedPost.key
      };
    case TYPE_TO_ROUTE.story:
      const selectedStory = store.stories[slug];

      return {
        slug: selectedStory.slug,
        key: selectedStory.key
      };
    default:
      return { slug };
  }
};
