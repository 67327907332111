import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { createAction, createReducer } from '../utils';
import { getFeedbackAndFavoritesFromAction } from '../../helpers/content-interaction';
import { hasHigherOrder, isStatus, savedItemFromAction } from '../../helpers/status-data';

const GET_CONTENT_INTERACTION_REQUEST = 'GET_CONTENT_INTERACTION_REQUEST';
const GET_CONTENT_INTERACTION_SUCCESS = 'GET_CONTENT_INTERACTION_SUCCESS';
const GET_CONTENT_INTERACTION_FAILURE = 'GET_CONTENT_INTERACTION_FAILURE';

const SAVE_FAVORITE_REQUEST = 'SAVE_FAVORITE_REQUEST';
const SAVE_FAVORITE_SUCCESS = 'SAVE_FAVORITE_SUCCESS';
const SAVE_FAVORITE_FAILURE = 'SAVE_FAVORITE_FAILURE';

const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
const REMOVE_FAVORITE_FAILURE = 'REMOVE_FAVORITE_FAILURE';

const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

const SEND_CONTENT_STATUS_REQUEST = 'SEND_CONTENT_STATUS_REQUEST';
export const SEND_CONTENT_STATUS_SUCCESS = 'SEND_CONTENT_STATUS_SUCCESS';
const SEND_CONTENT_STATUS_FAILURE = 'SEND_CONTENT_STATUS_FAILURE';

export const makeFavorite = options =>
  createAction(
    {
      request: SAVE_FAVORITE_REQUEST,
      success: SAVE_FAVORITE_SUCCESS,
      failure: SAVE_FAVORITE_FAILURE
    },
    api => api.statusDataContent.sendStatusDataContent
  )(options);

export const removeFavorite = options =>
  createAction(
    {
      request: REMOVE_FAVORITE_REQUEST,
      success: REMOVE_FAVORITE_SUCCESS,
      failure: REMOVE_FAVORITE_FAILURE
    },
    api => api.statusDataContent.removeStatusDataContent
  )(options);

export const sendFeedback = ({ activityKey, activityStatusKey, data }) =>
  createAction(
    {
      request: SEND_FEEDBACK_REQUEST,
      success: SEND_FEEDBACK_SUCCESS,
      failure: SEND_FEEDBACK_FAILURE
    },
    api => api.statusDataContent.sendStatusDataContent
  )({
    activityKey,
    activityStatusKey,
    data
  });

export const sendContentStatus =
  ({ activityKey, activityStatusKey, data }) =>
  async (dispatch, getState) => {
    const { point, status } = await dispatch(
      createAction(
        {
          request: SEND_CONTENT_STATUS_REQUEST,
          success: SEND_CONTENT_STATUS_SUCCESS,
          failure: SEND_CONTENT_STATUS_FAILURE
        },
        api => api.statusDataContent.sendStatusDataContent
      )({
        activityKey,
        activityStatusKey,
        data
      })
    );

    const { userAttributes, contentInteraction } = getState();

    const dependencies = [data.key, status, contentInteraction.contentStatuses];

    if (isStatus(status) && hasHigherOrder(...dependencies)) {
      dispatch({
        type: SEND_CONTENT_STATUS_SUCCESS,
        payload: {
          contentKey: data.key,
          status,
          points: point,
          promotions: userAttributes.consents.promotions
        }
      });
    }
  };

export const getFeedbackAndFavorites = () =>
  createAction(
    {
      request: GET_CONTENT_INTERACTION_REQUEST,
      success: GET_CONTENT_INTERACTION_SUCCESS,
      failure: GET_CONTENT_INTERACTION_FAILURE
    },
    api => api.statusDataContent.getStatusDataContent
  )();

const initialState = {
  savedItems: {},
  feedback: {},
  contentStatuses: {},
  loading: true,
  error: false
};

export const contentInteraction = createReducer(initialState, {
  [GET_CONTENT_INTERACTION_REQUEST]: state => ({ ...state, loading: true, error: false }),
  [GET_CONTENT_INTERACTION_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: false,
    ...getFeedbackAndFavoritesFromAction(payload)
  }),
  [GET_CONTENT_INTERACTION_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SEND_FEEDBACK_REQUEST]: state => ({
    ...state,
    loading: true,
    error: false
  }),
  [SEND_FEEDBACK_SUCCESS]: (state, { params, payload }) => ({
    ...state,
    feedback: { ...state.feedback, [params.data.key]: payload },
    loading: false,
    error: false
  }),
  [SEND_FEEDBACK_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SAVE_FAVORITE_REQUEST]: state => ({ ...state, loading: true, error: false }),
  [SAVE_FAVORITE_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      loading: false,
      savedItems: { ...state.savedItems, [action.params.data.key]: savedItemFromAction(action) }
    };
  },
  [SAVE_FAVORITE_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [REMOVE_FAVORITE_REQUEST]: state => ({ ...state, loading: true }),
  [REMOVE_FAVORITE_SUCCESS]: (state, { params }) => {
    return {
      ...state,
      error: false,
      loading: false,
      savedItems: ObjectShim.omit(state.savedItems, [params.ownKey])
    };
  },
  [REMOVE_FAVORITE_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [SEND_CONTENT_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    contentStatuses: {
      ...state.contentStatuses,
      ...(payload.contentKey && { [payload.contentKey]: payload.status })
    }
  })
});
