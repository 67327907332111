import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../../styles/components/navigation-bar.module.scss';
import { setGlobalConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { NavLink } from '../link';

export const getNewConfig = (nativeTrigger, introducerCode) => async e => {
  if (nativeTrigger) {
    e.preventDefault();

    NativeAction.trigger(nativeTrigger);
  } else {
    await setGlobalConfig(introducerCode);
  }
};

export const NavigationBar = withMemoAndRef(({ items, top, className }, ref) => {
  const introducer = useSelector(state => state.account.introducer);

  return (
    <ul ref={ref} className={classnames(styles.container, { [styles.top]: top }, className)}>
      {items.map(({ icon, labelText, link, nativeTrigger }) => (
        <li key={labelText} className={styles.item}>
          <NavLink
            to={link}
            className={styles.link}
            activeClassName={styles.active}
            replace={(to, { pathname }) => pathname.includes(to)}
            onClick={getNewConfig(nativeTrigger, introducer)}
          >
            <AffiliateIcon name={icon} className={styles.icon} />
            <span className={styles.label}>{labelText}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
});
