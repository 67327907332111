import { users } from '../../../api/users';
import { USER_SERVICE_KEYS, UserService } from '../../../services/user-service';
import { ConfigService } from '../../../services/config-service';
import { createAction } from '../../utils';
import { getUserAttributes, RESET_USER_ATTRIBUTES } from '../user-attributes/actions';
import { getPoints, getRewardCards } from '../points-and-awards';
import { getFeedbackAndFavorites } from '../content-interaction';
import { getStatusData, sendStatus } from '../status-data';
import { getNextActivity } from '../digital-coach';
import { getConsentList } from '../consent-list';
import { getValidations } from '../validations';
import { addToast } from '../toast';
import * as ACCOUNT from './action-types';
import { createShadowUser } from './shadow-user-actions';
import { getUser } from './get-user-action';

export const setAccountData = (name, value) => ({ type: ACCOUNT.SET_ACCOUNT_DATA, name, value });
export const setAccountError = (name, errorMsg) => ({ type: ACCOUNT.SET_ACCOUNT_ERROR, name, errorMsg });

export const switchBusinessProfile = account => async dispatch => {
  const initialShadowId = UserService.getItem(USER_SERVICE_KEYS.SHADOW_ID);

  try {
    dispatch({ type: RESET_USER_ATTRIBUTES });
    dispatch({ type: ACCOUNT.SWITCH_BUSINESS_PROFILE_REQUEST, payload: { ...account } });
    UserService.setUserShadowId(account.userId);
    await dispatch(initUserData());
    dispatch({ type: ACCOUNT.SWITCH_BUSINESS_PROFILE_SUCCESS });
  } catch (error) {
    UserService.setUserShadowId(initialShadowId);
    await dispatch(initUserData());
    dispatch({ type: ACCOUNT.SWITCH_BUSINESS_PROFILE_FAILURE });
    throw new Error('Error while switching business profile. Data has been reverted to the previously selected.', {
      error
    });
  }
};

/**
 * This function is used to initialize user data based on shadowId saved into the LocalStorage.
 */
const initUserData = () => async dispatch => {
  UserService.track();

  await dispatch(getUser());
  await dispatch(getStatusData());
  await dispatch(getFeedbackAndFavorites());
  await dispatch(getValidations());
  await dispatch(getUserAttributes());
  await dispatch(getNextActivity());

  const { POINTS, REWARDS, MARKETING } = ConfigService.get('DISPLAY_MODULES');

  if (POINTS) {
    await dispatch(getPoints());

    if (REWARDS) {
      await dispatch(getRewardCards());
    }
  }

  if (MARKETING) {
    await dispatch(getUserMarketingPreferences());
  }

  UserService.removeHasAccount();
};

export const login = (successCallback, goToCodeCallback) => async (dispatch, getState) => {
  const { email, password } = getState().account;

  dispatch({ type: ACCOUNT.LOGIN_REQUEST });

  const response = await users.login({ email: email || UserService.getItem(USER_SERVICE_KEYS.EMAIL), password });

  if (response.ok) {
    const result = await response.json();

    successCallback();

    await UserService.login(result);
    await dispatch(initUserData());

    dispatch({ type: ACCOUNT.LOGIN_SUCCESS });
  } else {
    const { message: errorMsg } = await response.json();

    if (errorMsg && errorMsg === 'User is not confirmed.') {
      goToCodeCallback();
      await dispatch(resendCodeSignUp());
    }

    dispatch({ type: ACCOUNT.LOGIN_FAILURE, errorMsg });
  }
};

export const signUp = callback => async (dispatch, getState) => {
  const { email, password } = getState().account;

  const attributes = {
    'custom:shadowId': UserService.getItem(USER_SERVICE_KEYS.SHADOW_ID),
    'custom:signUpConfirmType': 'code'
  };

  const response = await dispatch(
    createAction(
      {
        request: ACCOUNT.SIGN_UP_REQUEST,
        success: ACCOUNT.SIGN_UP_SUCCESS,
        failure: ACCOUNT.SIGN_UP_FAILURE
      },
      api => api.users.signUp
    )({ email: email || UserService.getItem(USER_SERVICE_KEYS.EMAIL), password, attributes })
  );

  dispatch(setAccountData('code', ''));

  if (!response.errorMsg) {
    await dispatch(sendStatus('accountCreated', 'inProgress'));
    callback();
  }
};

export const resendCodeSignUp = () => async (dispatch, getState) => {
  const { email } = getState().account;

  const response = await dispatch(
    createAction(
      {
        request: ACCOUNT.RESEND_CODE_SIGN_UP_REQUEST,
        success: ACCOUNT.RESEND_CODE_SIGN_UP_SUCCESS,
        failure: ACCOUNT.RESEND_CODE_SIGN_UP_FAILURE
      },
      api => api.users.resendCodeSignUp
    )({ email: email || UserService.getItem(USER_SERVICE_KEYS.EMAIL) })
  );

  const {
    DISPLAY_COMPONENTS: { TOAST },
    TOAST: {
      messages: { account }
    }
  } = window.CONFIG;

  if (!response.errorMsg && TOAST.ACCOUNT) {
    dispatch(addToast({ message: account.text, to: account.to }));
  } else {
    dispatch(addToast({ message: account.error || response.errorMsg, to: account.to }));
  }
};

export const confirmSignUp = (successCallback, failureCallback) => async (dispatch, getState) => {
  const { code, email } = getState().account;

  const params = {
    email: email || UserService.getItem(USER_SERVICE_KEYS.EMAIL),
    code: code.toString()
  };

  dispatch({ type: ACCOUNT.CONFIRM_SIGN_UP_REQUEST });

  const response = await users.confirmSignUp(params);

  if (response.ok) {
    dispatch(setAccountData('password', ''));
    dispatch(setAccountData('code', ''));

    if (!email) {
      dispatch(setAccountData('email', UserService.getItem(USER_SERVICE_KEYS.EMAIL)));
    }

    UserService.removeItem(USER_SERVICE_KEYS.EMAIL);

    await dispatch(sendStatus('accountCreated', 'completed'));
    UserService.setHasAccount();

    setTimeout(() => dispatch({ type: ACCOUNT.CONFIRM_SIGN_UP_SUCCESS }), 0);

    successCallback();
  } else {
    dispatch({ type: ACCOUNT.CONFIRM_SIGN_UP_FAILURE });
    failureCallback();
  }
};

export const resetCode = callback => async (dispatch, getState) => {
  const { email } = getState().account;

  const response = await dispatch(
    createAction(
      {
        request: ACCOUNT.RESET_CODE_REQUEST,
        success: ACCOUNT.RESET_CODE_SUCCESS,
        failure: ACCOUNT.RESET_CODE_FAILURE
      },
      api => api.users.resetCode
    )({ username: email || UserService.getItem(USER_SERVICE_KEYS.EMAIL) })
  );

  const {
    DISPLAY_COMPONENTS: { TOAST },
    TOAST: {
      messages: { account }
    }
  } = window.CONFIG;

  if (!response.errorMsg) {
    dispatch(setAccountData('code', ''));
    callback && callback();

    TOAST.ACCOUNT && dispatch(addToast({ message: account.text, to: account.to }));
  } else {
    TOAST.ACCOUNT && dispatch(addToast({ message: account.error || response.errorMsg, to: account.to }));
  }
};

export const resetPassword = (successCallback, failureCallback) => async (dispatch, getState) => {
  const { newPassword, code, email } = getState().account;

  dispatch({ type: ACCOUNT.RESET_PASSWORD_REQUEST });

  const response = await users.resetPassword({
    password: newPassword,
    code: code.toString(),
    username: email ? email : UserService.getItem(USER_SERVICE_KEYS.EMAIL)
  });

  if (response.ok) {
    successCallback();

    dispatch(setAccountData('newPassword', ''));
    dispatch(setAccountData('code', ''));
    UserService.removeItem(USER_SERVICE_KEYS.EMAIL);
    setTimeout(() => dispatch({ type: ACCOUNT.RESET_PASSWORD_SUCCESS }), 0);
  } else {
    const error = await response.json();

    dispatch({ type: ACCOUNT.RESET_CODE_FAILURE, errorMsg: error.message });

    failureCallback();
  }
};

export const updateUser = params =>
  createAction(
    {
      request: ACCOUNT.UPDATE_USER_REQUEST,
      success: ACCOUNT.UPDATE_USER_SUCCESS,
      failure: ACCOUNT.UPDATE_USER_FAILURE
    },
    api => api.users.updateUser
  )(params);

export const updatePassword = callback => async (dispatch, getState) => {
  const { password, newPassword } = getState().account;

  dispatch({ type: ACCOUNT.UPDATE_PASSWORD_REQUEST });

  const { accessToken, refreshToken } = UserService.getTokens();

  const data = {
    oldPassword: password,
    newPassword,
    accessToken,
    refreshToken
  };

  const response = await users.updatePassword(data);

  if (response.ok) {
    callback();

    dispatch(setAccountData('password', ''));
    dispatch(setAccountData('newPassword', ''));
    setTimeout(() => dispatch({ type: ACCOUNT.UPDATE_PASSWORD_SUCCESS }), 0);
  } else {
    const error = await response.json();

    dispatch({ type: ACCOUNT.UPDATE_PASSWORD_FAILURE, errorMsg: error.message });
  }
};

export const logout = callback => async dispatch => {
  dispatch({ type: ACCOUNT.LOG_OUT_REQUEST });

  const response = await users.logout({
    accessToken: UserService.getItem(USER_SERVICE_KEYS.ACCESS_TOKEN),
    refreshToken: UserService.getItem(USER_SERVICE_KEYS.REFRESH_TOKEN)
  });

  if (response.ok) {
    callback();

    UserService.logout();

    setTimeout(() => dispatch({ type: ACCOUNT.LOG_OUT_SUCCESS }), 0);
  } else {
    dispatch({ type: ACCOUNT.LOG_OUT_FAILURE });
  }
};

export const deleteAccount = callback => async dispatch => {
  dispatch({ type: ACCOUNT.DELETE_ACCOUNT_REQUEST });

  const response = await users.deleteAccount();

  if (response.ok) {
    callback();

    UserService.clear();
    await dispatch(createShadowUser());
    await dispatch(getStatusData());
    await dispatch(getFeedbackAndFavorites());
    await dispatch(getConsentList());
    UserService.track();

    dispatch({ type: ACCOUNT.DELETE_ACCOUNT_SUCCESS });
  } else {
    dispatch({ type: ACCOUNT.DELETE_ACCOUNT_FAILURE });
  }
};

export const deleteProfile =
  ({ userId, callback }) =>
  async (dispatch, getState) => {
    dispatch({ type: ACCOUNT.DELETE_PROFILE_REQUEST });

    try {
      await users.deleteBusinessProfile(userId);
      const businessProfiles = Object.values(getState().account.profiles);
      const isNotLastProfile = businessProfiles.length > 1;

      if (isNotLastProfile) {
        const firstDifferentProfile = businessProfiles.find(profile => profile.userId !== userId);
        await dispatch(switchBusinessProfile(firstDifferentProfile));
      } else {
        UserService.removeShadowUser();
        await dispatch(initUserData());
        UserService.track();
      }

      callback();
      dispatch({ type: ACCOUNT.DELETE_PROFILE_SUCCESS });
    } catch (error) {
      dispatch({ type: ACCOUNT.DELETE_PROFILE_FAILURE });
    }
  };

export const checkEmail = (flow, onSucceed, onFailed) => async (dispatch, getState) => {
  const { REQUEST, SUCCESS, FAILURE } = ACCOUNT.getCheckEmailTypesByFlow(flow);

  const { email } = getState().account;

  dispatch({ type: REQUEST });

  const response = await users.checkEmail({ email, flow });

  if (response.ok) {
    dispatch({ type: SUCCESS });

    UserService.setItem(USER_SERVICE_KEYS.EMAIL, email);

    onSucceed && onSucceed();
  } else {
    const { message: errorMsg } = await response.json();

    dispatch({ type: FAILURE, errorMsg });

    onFailed && onFailed();
  }
};

export const getUserMarketingPreferences = () =>
  createAction(
    {
      request: ACCOUNT.GET_MARKETING_PREFERENCES_REQUEST,
      success: ACCOUNT.GET_MARKETING_PREFERENCES_SUCCESS,
      failure: ACCOUNT.GET_MARKETING_PREFERENCES_FAILURE
    },
    api => api.users.getMarketingPreferences
  )();

export const setUserMarketingPreferences = (marketingListIds, onSucceed) => async dispatch => {
  const response = await dispatch(
    createAction(
      {
        request: ACCOUNT.SET_MARKETING_PREFERENCES_REQUEST,
        success: ACCOUNT.SET_MARKETING_PREFERENCES_SUCCESS,
        failure: ACCOUNT.SET_MARKETING_PREFERENCES_FAILURE
      },
      api => api.users.setMarketingPreferences
    )({ marketingListIds })
  );

  if (!response.errorCode && onSucceed) {
    onSucceed();
  }
};

export const updateUserMarketingPreferences = ids => ({ type: ACCOUNT.UPDATE_MARKETING_PREFERENCES, ids });

export const getBusinessProfiles = () =>
  createAction(
    {
      request: ACCOUNT.GET_BUSINESS_PROFILES_REQUEST,
      success: ACCOUNT.GET_BUSINESS_PROFILES_SUCCESS,
      failure: ACCOUNT.GET_BUSINESS_PROFILES_FAILURE
    },
    api => api.users.getBusinessProfiles
  )();
