import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../../components/layout/page-header';
import styles from '../../../styles/widgets/story-step/header.module.scss';

const HeaderComponent = ({ title, subTitle, identifier }) => (
  <Header className={styles.header} heading={title} subheading={subTitle} category={identifier} />
);

HeaderComponent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  identifier: PropTypes.string
};

export { HeaderComponent };
