import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CoachMessage } from '../../components/coach-message';
import { FormExplainer } from '../../components/inputs/form-explainer';
import { Form, InputsWrapper } from '../../components/inputs/input-group';
import { CloseButton } from '../../components/layout/application-header/components/close-button';
import { Column } from '../../components/layout/grid';
import { Footer } from '../../components/layout/page-footer';
import { Header } from '../../components/layout/page-header';
import { PageAction } from '../../components/page-actions-section/components/page-action';
import { ProgressIndicator } from '../../components/progress-indicator';
import { Slot } from '../../components/slots';
import { Template, TImage } from '../../components/template';
import { checkExitBlockVisibility } from '../../helpers/questions/exit-block';
import { getRoutesByPathname } from '../../helpers/utils';
import { ConfigService } from '../../services/config-service';
import styles from '../../styles/templates/question-template.module.scss';
import { TrustCardComponent } from '../../pages/custom-page/components/trust-card';
import { extractPartnerData } from '../../helpers/marketplace';

export const Question = React.memo(
  ({
    slotName,
    message,
    innerHtml,
    heading,
    subHeading,
    identifier,
    modal,
    atid,
    isPager,
    pageNumber,
    pagesTotal,
    hideCloseButton = false,
    hidePageActions = false,
    children,
    activity,
    headerImage,
    backgroundPicture,
    description,
    isRequired,
    hidePartnerDetails,
    partner
  }) => {
    const {
      exitBlock,
      pageHeader,
      PROGRESS_INDICATOR = {}
    } = ConfigService.get('QUESTIONS', {
      exitBlock: { show: false }
    });
    const { type, edge } = PROGRESS_INDICATOR;

    const { pathname } = useLocation();
    const [, pathnameActivity] = getRoutesByPathname(pathname);

    const isExitBlockHidden = checkExitBlockVisibility({
      activityKey: activity?.key,
      hiddenForActivities: exitBlock.hiddenForActivities,
      pathnameActivity,
      hidePageActions,
      isEnabled: exitBlock.show
    });

    const isLegendExists = !!(innerHtml || message);

    const partnerData = useMemo(() => extractPartnerData(partner), [partner]);

    return (
      <>
        {!hideCloseButton && (
          <Slot name='top-navigation-right'>
            <CloseButton />
          </Slot>
        )}
        {heading && (
          <Header
            className={styles.header}
            heading={heading}
            subheading={subHeading}
            category={identifier}
            image={headerImage}
            type={pageHeader?.type}
            backgroundPosition={pageHeader?.backgroundPosition}
          />
        )}
        {isPager && <ProgressIndicator pageNumber={pageNumber} pagesTotal={pagesTotal} type={type} edge={edge} grid />}
        <Template className={styles.body} data-atid={atid}>
          {backgroundPicture && <TImage renderAs='section' src={backgroundPicture} className={styles.image} />}
          <Column className={styles['form-group']}>
            {partnerData && <TrustCardComponent headerAs='h1' hideDetails={hidePartnerDetails} partner={partnerData} />}
            <Form type='coach' aria-label={heading} tabIndex={0} aria-required={isRequired} question>
              <InputsWrapper isFieldSet={isLegendExists}>
                {isLegendExists ? (
                  <legend>
                    <CoachMessage appendSlot={slotName} html={innerHtml} isRequired={isRequired}>
                      {message}
                    </CoachMessage>
                  </legend>
                ) : null}
                {description ? (
                  <FormExplainer tabIndex={0} role='term'>
                    {description}
                  </FormExplainer>
                ) : null}
                {children}
              </InputsWrapper>
            </Form>
            {!isExitBlockHidden && (
              <PageAction to={exitBlock.to} type={exitBlock.type} iconName={exitBlock.iconName}>
                {exitBlock.label}
              </PageAction>
            )}
          </Column>
        </Template>
        <Footer pageType='question' />
        {modal}
      </>
    );
  }
);

Question.displayName = 'Question';
