import { compose, defaultProps, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { withMetaTags } from '../../../components/meta-tags';
import { sortWidgets } from '../../../helpers/dashboard';

const SEO_PAGE_DATA_NAME = 'dashboard';

const mapStateToProps = state => ({
  metaData: state.content.seoPages[SEO_PAGE_DATA_NAME]
});

export const withDashboard = widgets =>
  compose(
    connect(mapStateToProps),
    defaultProps({
      widgets
    }),
    withMetaTags(SEO_PAGE_DATA_NAME),
    withPropsOnChange([], () => ({
      widgetsToShow: sortWidgets(window.CONFIG.DASHBOARD.widgets ?? {})
    }))
  );
