import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/components/knowledge-base-topic.module.scss';
import { ConfigService } from '../../../services/config-service';
import { KnowledgeBaseItem } from '../item';
import { TopicHeader } from './topic-header';

const PlainTopic = React.memo(({ category, subHeading, slug, topicFilter, posts = [] }) => {
  const {
    TEMPLATE: { topicIcons, list, statusLabels = {} }
  } = ConfigService.get('ARTICLES_HOME_PAGE', {});

  const items =
    topicFilter === 'All' ? posts : posts.filter(({ homebuying_stage }) => homebuying_stage?.includes(topicFilter));

  return (
    <>
      {items.length ? (
        <>
          <div className={styles.container}>
            <TopicHeader topicIcons={topicIcons} category={category} subHeading={subHeading} slug={slug} />
          </div>
          <ul className={styles.menu}>
            {items.map(post => (
              <KnowledgeBaseItem
                key={post.id}
                item={post}
                showThumbnailImage={list?.showThumbnailImage}
                showStatusLabel={list?.showStatusLabel}
                showStatusIcon={list?.showStatusIcon}
                statusLabels={statusLabels}
              />
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
});

PlainTopic.propTypes = {
  category: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  slug: PropTypes.string,
  topicFilter: PropTypes.string,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      thumbnail: PropTypes.string
    })
  )
};

PlainTopic.displayName = 'PlainTopic';

export { PlainTopic };
