import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import { ariaDisabledInterceptor } from '../../../helpers/aria-disabled-interceptor';
import styles from '../../../styles/components/wheel.module.scss';
import { subscribe, unsubscribe } from '../../../store/reducers/wheels';
import { withResize } from '../../with-resize';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withContainer } from '../input-field';
import { useValidator } from '../../../helpers/hooks/use-validator';
import { useController } from './hooks/use-controller';
import { useView } from './hooks/use-view';
import { useFormatter } from './hooks/use-formatter';
import { WheelLabel } from './wheel-label';

const WheelComponent = React.memo(
  ({
    value = 0,
    increment = 10,
    validators,
    dynamic = false,
    className,
    name,
    label,
    informer,
    computed,
    prefix = '',
    suffix = '',
    format,
    dimensions,
    subscribe,
    unsubscribe,
    stopSiblings,
    onChange,
    onChangeEnd
  }) => {
    const [controller, { handleStart, handleIncrease, handleDecrease, stop }] = useController(value, increment);
    const [view, windowRef] = useView(dimensions);

    const { error, errors } = useValidator(value, validators);

    const handleDragStart = event => {
      stopSiblings();

      event.stopPropagation();
      event.preventDefault();
    };

    const handleDrag = event => {
      const { multiplier, boundary } = event.detail;

      if (onChange && !errors[boundary]) {
        const nextValue = NumberShim.toNearestOf(value, increment * multiplier);

        onChange(name)(nextValue);
      }

      event.stopPropagation();
      event.preventDefault();
    };

    const handleDragEnd = event => {
      if (onChangeEnd) {
        onChangeEnd(name)(value);
      }

      event.stopPropagation();
      event.preventDefault();
    };

    const handleTap = useCallback(
      handle => event => {
        stopSiblings();

        handle(event);

        if (onChangeEnd) {
          PromiseShim.queueMicrotask(() => {
            onChangeEnd(name)(value);
          });
        }
      },
      [name, onChangeEnd, stopSiblings, value]
    );

    useEffect(() => {
      if (dynamic) {
        subscribe(name, stop);

        return () => {
          unsubscribe(name);
        };
      }
    }, [name, dynamic, subscribe, unsubscribe, stop]);

    useEffect(() => {
      return () => {
        stop();
      };
    }, [stop]);

    const { transform, transition } = controller;
    const { grips, gripWidth, zTranslate, rotationPerPanel, steppersDisplay } = view;

    const formatted = useFormatter(value, computed, format);
    const fullValue = `${prefix} ${formatted.value} ${suffix}`;
    const wheelInputId = `${name}-wheel-group`;
    const decreaseAriaLabel = `decrease ${label} by ${prefix} ${increment} ${suffix}`;
    const increaseAriaLabel = `increase ${label} by ${prefix} ${increment} ${suffix}`;

    return (
      <div className={classnames(styles.container, className)} data-atid={name && `${name}-slider`}>
        <div className={styles.text}>
          {label && (
            <div className={styles.left}>
              <WheelLabel className={styles.label} informer={informer} label={label} />
            </div>
          )}
          <div className={classnames(styles.center, styles.value)}>
            <span>{fullValue}</span>
          </div>
          <div className={classnames(styles.right, styles.computed)}>
            <span>{formatted.computed}</span>
          </div>
        </div>
        <div
          id={wheelInputId}
          className={styles.wrapper}
          onDrag={handleDrag}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {steppersDisplay && (
            <button
              className={styles.stepper}
              onClick={ariaDisabledInterceptor(handleTap(handleDecrease))}
              role='spinbutton'
              aria-disabled={errors.min}
              aria-label={decreaseAriaLabel}
              aria-valuetext={fullValue}
            >
              <AffiliateIcon name='stepper-decrease' />
            </button>
          )}
          <div className={classnames(styles.indicator, { [styles.error]: error })} />
          <div className={styles.body}>
            <div className={classnames(styles.fader, styles.left)}></div>
            <div ref={windowRef} className={styles.window}>
              {/*The disadvantage of a very custom implementation.*/}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <ul
                style={{ width: gripWidth, transform, transition }}
                className={styles.rotator}
                onMouseDown={handleStart}
                onTouchStart={handleStart}
              >
                {ArrayShim.range(grips).map(el => {
                  const yRotation = rotationPerPanel * el;

                  return (
                    <li
                      key={yRotation}
                      className={styles.grip}
                      style={{
                        width: gripWidth,
                        transform: `rotateY(${yRotation}deg) translateZ(${zTranslate}px)`
                      }}
                      aria-hidden={true}
                      tabIndex={-1}
                    >
                      {el}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={classnames(styles.fader, styles.right)}></div>
          </div>
          {steppersDisplay && (
            <button
              className={styles.stepper}
              onClick={ariaDisabledInterceptor(handleTap(handleIncrease))}
              role='spinbutton'
              aria-label={increaseAriaLabel}
              aria-disabled={errors.max}
              aria-valuetext={fullValue}
            >
              <AffiliateIcon name='stepper-increase' />
            </button>
          )}
        </div>
      </div>
    );
  }
);

WheelComponent.displayName = 'WheelComponent';

export const Wheel = compose(
  connect(
    (state, { name }) => ({
      validators: state.validations.validations,
      stopSiblings: compose(
        ...state.wheels.dynamic.reduce((callbacks, [wheel, stopMomentum]) => {
          if (wheel !== name) {
            return [...callbacks, stopMomentum];
          }

          return callbacks;
        }, [])
      )
    }),
    { subscribe, unsubscribe }
  ),
  withPropsOnChange(['name', 'min', 'max'], ({ name, min, max, validators: state }) => {
    const centralizeMin = state.goal[name]?.validationData.min;
    const centralizeMax = state.goal[name]?.validationData.max;

    const minLimit = min ?? centralizeMin;
    const maxLimit = max ?? centralizeMax;

    const validators = {};

    if (Number.isFinite(minLimit)) {
      validators.min = value => value <= minLimit;
    }

    if (Number.isFinite(maxLimit)) {
      validators.max = value => value >= maxLimit;
    }

    return { validators };
  }),
  withResize
)(WheelComponent);

Wheel.displayName = 'Wheel';

export const InputWheel = withContainer(Wheel);

InputWheel.displayName = 'InputWheel';
