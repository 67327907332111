import React from 'react';
import { branch, compose, renderNothing, withHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { NavLink } from '../../components/link';
import { AffiliateIcon } from '../../components/icons/affiliate-icon';
import { setNavKnowledgeLink } from '../../store/reducers/navigation';
import styles from '../../styles/components/knowledge-base-item.module.scss';
import { getStatusPlain } from '../../helpers/custom-page/is-completed';
import { Image as ImageBase } from '../../components/images/image';

const KnowledgeBaseItemComponent = React.memo(props => {
  const {
    item,
    showStatusIcon,
    showThumbnailImage,
    showStatusLabel,
    statusLabel,
    handleClick,
    statusStyles,
    iconName
  } = props;
  const { title, slug, thumbnail } = item;

  const isDisplayThumbnail = showThumbnailImage && StringShim.isString(thumbnail);
  const isDisplayGraphics = isDisplayThumbnail || showStatusIcon;

  return (
    <li className={styles.container} style={statusStyles}>
      <NavLink
        className={styles.button}
        activeClassName={styles.selected}
        to={`/articles/${slug}`}
        onClick={handleClick}
      >
        {isDisplayGraphics && (
          <div
            className={classnames(styles.graphics, {
              [styles['thumbnail-size']]: isDisplayThumbnail,
              [styles['status-icon-size']]: showStatusIcon && !isDisplayThumbnail
            })}
          >
            {isDisplayThumbnail && (
              <div className={classnames(styles.thumbnail, styles.overlay, styles['thumbnail-size'])}>
                <ImageBase src={thumbnail} title={title} cover fluid />
              </div>
            )}
            {showStatusIcon && (
              <AffiliateIcon
                className={classnames(styles['status-icon'], styles['status-icon-size'])}
                name={iconName}
              />
            )}
          </div>
        )}
        <div className={styles['labels-container']}>
          <p className={styles.label}>{title}</p>
          {showStatusLabel ? <span className={styles['status-label']}>{statusLabel}</span> : null}
        </div>
        <AffiliateIcon name='checklist-action' className={styles.action} />
      </NavLink>
    </li>
  );
});

export const KnowledgeBaseItem = compose(
  branch(({ item: { isVisible } }) => !isVisible, renderNothing),
  connect(state => ({ contentStatuses: state.contentInteraction.contentStatuses }), { setNavKnowledgeLink }),
  withHandlers({
    handleClick:
      ({ item, setNavKnowledgeLink }) =>
      () => {
        setNavKnowledgeLink(item);
      }
  }),
  withPropsOnChange(['contentStatuses'], ({ contentStatuses, statusLabels, item }) => {
    const status = getStatusPlain(contentStatuses[item.slug]) || 'notSeen';

    const statusStyles = {
      '--app-custom-knowledge-item-image-tint': `var(--knowledge-item-image-tint-${status}-color)`,
      '--app-custom-knowledge-item-image-border': `var(--knowledge-item-image-${status}-border)`,
      '--app-custom-knowledge-item-status-icon-container-background': `var(--knowledge-item-status-icon-container-${status}-background)`,
      '--app-custom-knowledge-item-status-icon-container-border': `var(--knowledge-item-status-icon-container-${status}-border)`,
      '--app-custom-knowledge-item-status-icon-color': `var(--knowledge-item-status-icon-${status}-color)`,
      '--app-custom-knowledge-item-status-label-color': `var(--knowledge-item-status-label-${status}-colour)`
    };

    return {
      statusLabel: statusLabels[status] || status,
      iconName: `article-indicator-${status}`,
      statusStyles
    };
  })
)(KnowledgeBaseItemComponent);
