import React from 'react';
import { compose } from 'recompose';
import { RadioGroup } from '../../../components/inputs/radio/radio-group';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(withContainer, withButton, withPreSubmitButton)(RadioGroup);

export const QuestionRadioInput = React.memo(
  ({
    value,
    buttonLabel,
    buttonType,
    items,
    plainValue,
    userAttributeField,
    onChange,
    onInput,
    onSubmit,
    loading,
    disabled,
    preSubmitButton,
    sendStatus,
    isOptional,
    styling
  }) => {
    return (
      <Input
        value={value}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        items={items}
        plainValue={plainValue}
        userAttributeField={userAttributeField}
        disabled={disabled}
        onChange={onChange}
        onTextChange={onInput}
        onSubmit={onSubmit}
        loading={loading}
        preSubmitButton={preSubmitButton}
        sendStatus={sendStatus}
        isOptional={isOptional}
        styling={styling}
      />
    );
  }
);
