import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { AffiliateIcon } from '@apps/firsthomecoach/src/components/icons/affiliate-icon';
import Card from '@apps/firsthomecoach/src/widgets/card';
import { TYPE_TO_ROUTE } from '@apps/firsthomecoach/src/helpers/content-interaction/constants';
import { Column, Grid } from '@packages/ui-kit/core/grid';
import { useScrollRestoration } from '@apps/firsthomecoach/src/components/scroll-position/hooks/use-scroll-restoration';
import styles from '../../styles/templates/content-feed-template.module.scss';

const List = React.memo(props => {
  const {
    items = [],
    loading,
    isSavedStatus,
    emptyCategoryText,
    emptyEmptyStateText,
    repeaterKey,
    isDisplayItemSubTitle,
    isDisplayItemExcerpt,
    isDisplayCardSaveButton,
    onCardInView
  } = props;

  const { restoreScrollPosition } = useScrollRestoration();

  useLayoutEffect(() => {
    if (!loading) {
      restoreScrollPosition();
    }
  }, [loading, restoreScrollPosition]);

  if (loading) {
    return (
      <Column className={styles.centered}>
        <AffiliateIcon name='loading' />
      </Column>
    );
  }

  if (!items.length) {
    return (
      <Column className={styles.centered}>
        <span className={styles.emptyText}>{isSavedStatus ? emptyEmptyStateText : emptyCategoryText}</span>
      </Column>
    );
  }

  return (
    <Grid className={styles.grid} fill>
      {items.map(
        ({
          id,
          ownKey,
          slug,
          type,
          image,
          imageUrl,
          title,
          subTitle: subTitleProp,
          excerpt: excerptProp,
          label,
          url
        }) => {
          const subTitle = isDisplayItemSubTitle ? subTitleProp : '';
          const excerpt = isDisplayItemExcerpt ? excerptProp : '';

          return (
            <div key={ownKey} className={styles.centered}>
              <Card
                id={id}
                type={type}
                ownKey={ownKey}
                repeaterKey={repeaterKey}
                slug={slug}
                href={`/${TYPE_TO_ROUTE[type]}/${slug}`}
                image={image || imageUrl}
                videoUrl={url}
                title={title}
                subTitle={subTitle}
                excerpt={excerpt}
                label={label}
                onCardInView={onCardInView}
                isDisplayCardSaveButton={isDisplayCardSaveButton}
              />
            </div>
          );
        }
      )}
    </Grid>
  );
});

List.displayName = 'ContentFeedItems';

List.propTypes = {
  loading: PropTypes.bool,
  isSavedStatus: PropTypes.bool,
  emptyCategoryText: PropTypes.string.isRequired,
  emptyEmptyStateText: PropTypes.string.isRequired,
  repeaterKey: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['article', 'video', 'story', 'blog', 'activity', 'project']).isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  isDisplayItemSubTitle: PropTypes.bool,
  isDisplayItemExcerpt: PropTypes.bool,
  isDisplayCardSaveButton: PropTypes.bool,
  onCardInView: PropTypes.func.isRequired
};

export default List;
