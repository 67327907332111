import { LocalStorageService } from '@packages/helpers/core/services/local-storage';

export class ArticlesFeedback {
  static store = new LocalStorageService('articles-feedback');

  static init(name) {
    return ArticlesFeedback.store.set(store => ({
      ...store,
      [name]: null
    }));
  }

  static get(name) {
    if (!ArticlesFeedback.has(name)) {
      ArticlesFeedback.init(name);
    }

    return ArticlesFeedback.store.get()[name];
  }

  static set(name, value) {
    return ArticlesFeedback.store.set(store => ({
      ...store,
      [name]: value
    }));
  }

  static has(name) {
    return name in ArticlesFeedback.store.get();
  }

  static getAll() {
    return ArticlesFeedback.store.get();
  }
}
