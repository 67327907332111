import React from 'react';
import { Helmet } from 'react-helmet';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { getSEOPageData } from '../store/reducers/content';

const Component = React.memo(({ tags }) => {
  const {
    title,
    description,
    image,
    canonical,
    og_description: ogDescription = description,
    og_image: ogImage = image,
    og_title: ogTitle = title,
    twitter_description: twitterDescription = description,
    twitter_image: twitterImage = image,
    twitter_title: twitterTitle = title,
    robots
  } = tags;
  const index = robots?.index ?? '';

  const affiliateCanonical = canonical ? canonical : window.location.href.split('?')[0];
  const formattedDescription = description ? description : ogDescription;
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {formattedDescription && <meta name='description' content={formattedDescription} />}
      {affiliateCanonical && <link rel='canonical' href={affiliateCanonical} />}
      <meta property='og:title' content={ogTitle ? ogTitle : title} />
      <meta property='og:description' content={ogDescription ? ogDescription : description} />
      <meta property='og:url' content={affiliateCanonical} />
      <meta property='og:image' content={ogImage ? ogImage : image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={twitterTitle ? twitterTitle : title} />
      <meta name='twitter:description' content={twitterDescription ? twitterDescription : description} />
      <meta name='twitter:image' content={twitterImage ? twitterImage : image} />
      {index === 'noindex' && <meta name='robots' content='noindex' />}
      {index === 'noindex' && <meta name='googlebot' content='noindex' />}
    </Helmet>
  );
});

const mapStateToProps = (state, { name, tags }) => ({
  tags: tags ?? state.content.seoPages[name]
});

export const MetaTags = compose(
  connect(mapStateToProps, { getSEOPageData }),
  lifecycle({
    componentDidMount() {
      const { getSEOPageData, tags, name } = this.props;

      if (!tags && name) {
        getSEOPageData({ name });
      }
    }
  }),
  branch(({ tags }) => !tags, renderNothing)
)(Component);

export const withMetaTags = name => Component => props =>
  (
    <>
      <MetaTags name={name} />
      <Component {...props} />
    </>
  );
