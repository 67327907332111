import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../../../styles/widgets/story-step/image.module.scss';

const ImageComponent = ({ image, imageAlt, objectFit = 'contain' }) => (
  <>{image && <img src={image} alt={imageAlt} className={classnames(styles.image, styles[objectFit])} />}</>
);

ImageComponent.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down'])
};

export { ImageComponent };
