import React, { useEffect, useMemo, useRef, useState } from 'react';
import { compose, defaultProps } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/input-list-popover.module.scss';
import { usePopper } from '../../../helpers/hooks/use-popper';
import { ScrollContainer } from '../../layout/scroll-container';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withSpinner } from '../../with-loader';

const Item = React.memo(({ index, focused, selected, disabled, onClick, onMouseDown, children }) => {
  const ref = useRef();

  useEffect(() => {
    const item = ref.current;

    if (item && focused) {
      const {
        parentElement: list,
        clientHeight: itemHeight,
        dataset: { index }
      } = item;

      const { clientHeight: listHeight, scrollTop } = list;

      // item's position related to the list;
      const offsetTop = itemHeight * index;

      if (offsetTop < scrollTop) {
        list.scrollTop = offsetTop - listHeight;
      }

      if (offsetTop + itemHeight > scrollTop + listHeight) {
        list.scrollTop = scrollTop + listHeight;
      }
    }
  }, [focused]);

  return (
    <li
      ref={ref}
      role='option'
      aria-selected={selected}
      aria-disabled={disabled}
      data-index={index}
      className={classnames(styles.item, { [styles.focused]: focused })}
      onClick={onClick}
      onKeyDown={onClick}
      onMouseDown={onMouseDown}
    >
      {children}
    </li>
  );
});

const ListComponent = React.memo(({ items, selected, focused, onChange }) => {
  // This will prevent Select Input trigger focus loosing.
  const prevent = event => event.preventDefault();

  return (
    <ScrollContainer renderAs='ul' role='listbox' className={styles.items}>
      {items.map((item, index) => {
        const { id, label, disabled } = item;

        return (
          <Item
            key={`${label}_${id}`}
            index={index}
            selected={label === selected}
            focused={id === focused}
            disabled={disabled}
            onClick={onChange(item)}
            onMouseDown={prevent}
          >
            <span className={styles.value}>{label}</span>
            {label === selected && <AffiliateIcon name='select-value' className={styles.icon} inline />}
          </Item>
        );
      })}
    </ScrollContainer>
  );
});

const List = compose(withSpinner)(ListComponent);

const FOCUSED_ARIA_VALUE_PREFIX = 'press Enter to select: ';

const useContainer = ({ defaultSelected, onChange, ...props }) => {
  const { name, value, items } = props;

  const [active, activate] = useState(false);
  const [focused, focus] = useState();
  const [selected, select] = useState(value ?? null);
  const [focusedAriaValue, setFocusedAriaValue] = useState();

  useEffect(() => {
    if (!active) {
      setFocusedAriaValue(undefined);
    }
  }, [active]);

  //Set tick icon over selected item on loading the page
  useEffect(() => {
    if (items.length && !selected) {
      const matchSelectedItem = items.find(item => {
        const test = defaultSelected ? defaultSelected(item.value) : item.value;

        return test.key === value;
      });

      select(matchSelectedItem?.label);
    }
  }, [items, defaultSelected, value, selected]);

  const open = event => {
    if (event) {
      event.preventDefault();
    }

    activate(true);
  };

  const close = event => {
    if (event) {
      event.preventDefault();
    }

    activate(false);
  };

  const handleChange =
    ({ id, value, label, disabled }) =>
    event => {
      if (disabled) {
        return false;
      }

      if (onChange) {
        onChange(name)(value, label, id);
      }

      select(label);
      close(event);
    };

  const setFocusedData = focusedItem => {
    if (focusedItem) {
      focus(focusedItem.id);
      const focusedAriaText = focusedItem.label !== selected ? FOCUSED_ARIA_VALUE_PREFIX + focusedItem.label : '';
      setFocusedAriaValue(focusedAriaText);
    }
  };

  const handleArrowKey = (key, event) => {
    open(event);

    if (items.length) {
      const index = items.findIndex(({ id }) => id === (focused ?? selected));

      if (key === 'ArrowUp') {
        const focusedItem = index > 0 ? items[index - 1] : items[items.length - 1];
        setFocusedData(focusedItem);
      }

      if (key === 'ArrowDown') {
        const focusedItem = index > -1 && index < items.length - 1 ? items[index + 1] : items[0];
        setFocusedData(focusedItem);
      }
    }
  };

  const handleEnterKey = event => {
    const item = items.find(({ id }) => id === focused);

    if (item) {
      handleChange(item)(event);
    }

    focus();
  };

  const handleKeyDown = event => {
    const { key } = event;

    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
        handleArrowKey(key, event);
        break;
      case 'Escape':
        close(event);
        break;
      case 'Enter':
        handleEnterKey(event);
        break;
      default:
        break;
    }
  };

  return {
    ...props,
    active: active && items.length > 0,
    focusedAriaValue,
    focused,
    selected,
    open,
    close,
    handleChange,
    handleKeyDown
  };
};

const withComponent = Component =>
  React.memo(props => {
    const {
      active,
      focused,
      selected,
      items,
      loading,
      className,
      popper,
      open,
      close,
      handleChange,
      handleKeyDown,
      focusedAriaValue,
      ...rest
    } = useContainer(props);

    const displayValue = useMemo(() => items.find(i => i.value.key === rest.value)?.label, [items, rest.value]);

    const { onTriggerRef, onPopoverRef } = usePopper(popper, active);

    return (
      <div className={classnames(styles.container, className)}>
        {/*The disadvantage of a very custom implementation.*/}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div ref={onTriggerRef} onClick={open} onInput={open} onKeyDown={handleKeyDown} onBlur={close}>
          <Component {...rest} displayValue={displayValue} ariaValue={focusedAriaValue} />
        </div>
        <div ref={onPopoverRef} className={styles.menu} hidden={!active} data-loading={loading}>
          <List items={items} selected={selected} focused={focused} loading={loading} onChange={handleChange} />
        </div>
      </div>
    );
  });

export const withPopover = props =>
  compose(
    defaultProps({
      items: [],
      ...props
    }),
    withComponent
  );
