import React from 'react';
import { compose } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { CalloutBlock } from '../../../components/callout';
import { sanitizeString } from '../../sanitize';
import { isHtmlElement } from '../../layout/element';
import { getAffiliateIconSync } from '../../layout/get-afiliate-icon';
import { messagesListToWrappedText } from '../../formatters/string-format';
import { getElementFromHtmlString } from './helpers';

/**
 * This helper replaces the callout block from the passed html doc with the callout component
 * @param element - html doc from WP
 * @return {*}
 */
export const changeCallout = element => {
  const elements = element.querySelectorAll('.callout');

  if (elements.length) {
    elements.forEach(callout => {
      if (!isHtmlElement(callout)) {
        return;
      }

      const description = [];
      const heading = callout.querySelector('.wp-block-heading');

      [...callout.children].forEach(child => {
        if (child.tagName === 'P') {
          description.push(child.innerText);
        }
      });

      const jsx = (
        <CalloutBlock
          icon={getAffiliateIconSync('callout')}
          heading={sanitizeString(heading)}
          description={messagesListToWrappedText(description, 'p')}
        />
      );

      const component = compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx);

      callout.parentNode.replaceChild(component, callout);
    });
  }

  return element;
};
