import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { sendAdditionalUserAttributes, setGeneratedData } from '../../../store/reducers/user-attributes/actions';
import { getStaticData } from '../../../store/reducers/static-data';
import { withSectionData } from '../../../pages/question/hocs/with-section-data';
import { getRoutesByPathname } from '../../../helpers/utils';
import { SECTION_CHECKPOINT_QUESTION_SLUG } from '../../../helpers/chekpoint-page/constants';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import {
  processDefaultValuesOnSectionSkip,
  processGeneratedDataOnSectionSkip
} from '../../../pages/question/helpers/question-section';

const SkipSectionComponent = ({ onClick, buttonType, label, ariaLabel }) => (
  <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
    {label}
  </ButtonWithIcon>
);

SkipSectionComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string
};

const mapStateToProps = state => ({ state });

export const SkipSectionAction = compose(
  connect(mapStateToProps, { sendAdditionalUserAttributes, getStaticData, setGeneratedData }),
  withRouter,
  withProps(({ location: { pathname }, data }) => {
    const [, activity] = getRoutesByPathname(pathname);

    return { activity, defaultValues: data };
  }),
  withSectionData,
  withHandlers({
    onClick:
      ({
        sendQuestionSectionInfo,
        sendAdditionalUserAttributes,
        questionSection,
        defaultValues,
        setGeneratedData,
        attributes,
        history,
        activity,
        state,
        link
      }) =>
      async () => {
        const { currentSection } = questionSection;

        if (currentSection) {
          sendQuestionSectionInfo({
            ...questionSection,
            endOfSection: currentSection
          });
        }

        if (defaultValues?.length > 0) {
          const requestData = processDefaultValuesOnSectionSkip({ defaultValues, state });

          const generatedData = processGeneratedDataOnSectionSkip({
            defaultValues,
            state: ObjectShim.mergeDeep(state, requestData)
          });

          // set generatedData to store without sending to BE
          if (!ObjectShim.isEmpty(generatedData)) {
            setGeneratedData(generatedData);
          }

          await sendAdditionalUserAttributes(requestData, true);
        }

        if (attributes && !ObjectShim.isEmpty(attributes)) {
          await sendAdditionalUserAttributes(ObjectShim.deepen(attributes), true);
        }

        const nextPageLink = link ?? `/checklist/${activity}/${SECTION_CHECKPOINT_QUESTION_SLUG}`;
        history.push(nextPageLink);
      }
  })
)(SkipSectionComponent);
