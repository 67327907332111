import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { createCustomPageProps } from '../../helpers/utils';
import { Question as QuestionLayout } from '../../templates/questions/question-template';
import { Loader, withLoadingHandlers } from '../../components/with-loader';
import { withMetaTags } from '../../components/meta-tags';
import { SectionPageTemplate } from '../../templates/questions/section-page-template';
import { withPageLoadingLifecycle } from '../../components/layout/content/with-native-loading-state';
import { withQuestion } from './hocs/with-question';
import { Input } from './input';
import { withLastQuestion } from './hocs/with-last-question';

const QuestionTemplate = React.memo(
  ({
    question,
    questionMessage,
    location: { pathname },
    identifier,
    children,
    onSubmit,
    loading,
    currentQuestion,
    turnOnLoading,
    turnOffLoading,
    disableNavigation
  }) => (
    <QuestionLayout
      atid={createCustomPageProps(pathname, '/').full}
      innerHtml={questionMessage}
      slotName={pathname}
      hideCloseButton={disableNavigation}
      {...currentQuestion}
    >
      {/*This allows to hide question inputs if there is no question message to avoid flickering, except empty string, as some questions can not have it*/}
      {!isNil(questionMessage) ? (
        <>
          {children
            ? children(question)
            : currentQuestion.questionInputs
                .sort((a, b) => a.order - b.order)
                .map(({ id, type, data, items }) => (
                  <Input
                    identifier={identifier}
                    key={id}
                    type={type}
                    data={data}
                    items={items}
                    onSubmit={onSubmit}
                    loading={loading}
                    turnOnLoading={turnOnLoading}
                    turnOffLoading={turnOffLoading}
                  />
                ))}
        </>
      ) : null}
    </QuestionLayout>
  )
);

const SEO_PAGE_DATA_NAME = 'default';

export const Question = compose(
  withMetaTags(SEO_PAGE_DATA_NAME),
  withLoadingHandlers(),
  withLastQuestion,
  withPageLoadingLifecycle,
  withQuestion,
  branch(({ question }) => !question, renderComponent(Loader)),
  branch(({ currentQuestion: { type } }) => type === 'questionSectionPage', renderComponent(SectionPageTemplate))
)(QuestionTemplate);
