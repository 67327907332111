import React from 'react';
import { compose, withProps } from 'recompose';
import classnames from 'classnames';
import { Column, Grid } from '../layout/grid';
import { createStepsArray } from '../../helpers/progress-indicator';
import styles from '../../styles/components/progress-indicator.module.scss';
import { ProgressIndicatorStep } from './step';

const ProgressIndicatorComponent = ({ steps, type, className }) => (
  <div className={classnames(styles.container, className)}>
    {steps.map(({ key, state, currentStep }) => (
      <ProgressIndicatorStep key={key} state={state} type={type} currentStep={currentStep} />
    ))}
    <div className={styles['key-line']} />
  </div>
);

const withWrapper =
  Component =>
  ({ edge, grid, forwardRef, ...props }) => {
    if (grid) {
      return (
        <Grid className={styles.wrapper} ref={forwardRef}>
          <Column flex justify='center' edge={edge}>
            <Component {...props} />
          </Column>
        </Grid>
      );
    }

    return <Component {...props} />;
  };

export const ProgressIndicator = compose(
  withProps(({ pageNumber, pagesTotal }) => ({
    steps: createStepsArray(pagesTotal, pageNumber)
  })),
  withWrapper
)(ProgressIndicatorComponent);
