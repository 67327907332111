export const sumByKey = (arr, key) => arr.reduce((a, b) => a + (b[key] || 0), 0);

export const calculateAge = dateOfBirth => {
  const date = dateOfBirth.split('/');
  const birthday = new Date(date[2], date[1], date[0]);

  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
