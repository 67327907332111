import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { setRefreshPageBackLink } from '../../../store/reducers/digital-coach';
import { updateUserMarketingPreferences } from '../../../store/reducers/account/actions';
import { digitalCoach as digitalCoachAPI } from '../../../api/digital-coach';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';

const MarketingButtonComponent = React.memo(
  ({
    updateUserMarketingPreferences,
    setRefreshPageBackLink,
    onConfirm,
    label,
    ariaLabel,
    callUrl,
    body,
    confirmationMessage,
    acceptButtonText,
    rejectButtonText,
    buttonType = 'major'
  }) => {
    const history = useHistory();

    const {
      location: { pathname }
    } = history;

    const onAccept = async () => {
      const response = await digitalCoachAPI.callCustomAction(callUrl, body);

      if (response.ok) {
        const keys = ObjectShim.truthyKeys(body);
        const ids = await response.json();

        updateUserMarketingPreferences(keys.map(key => ids[key]));
      }

      setRefreshPageBackLink(pathname);
      history.push('/updating-results');
    };

    const onClick = () =>
      onConfirm(onAccept, {
        children: <p>{confirmationMessage}</p>,
        acceptLabel: acceptButtonText,
        declineLabel: rejectButtonText
      });

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const MarketingButton = connect(null, { updateUserMarketingPreferences, setRefreshPageBackLink })(
  MarketingButtonComponent
);
