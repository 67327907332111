import { branch, compose, renderNothing, withProps } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { sendFeedback } from '../../../store/reducers/content-interaction';
import { ConfigService } from '../../../services/config-service';
import { TYPE_TO_ACTIVITY_KEY } from '../../../helpers/content-interaction/constants';
import { sortByWeight } from '../../../helpers/array';
import { FeedbackBlock } from '../../post-page/blocks/article-like';

const mapStateToProps = ({ content, contentInteraction }, { match }) => {
  const contentKey = content.videos[match.params.slug]?.key ?? '';

  return { contentKey };
};

export const VideoFeedback = compose(
  withRouter,
  connect(mapStateToProps, { sendFeedback }),
  withProps(({ feedbacks, match, storyKey }) => {
    const { text, actions, show } = ConfigService.get('VIDEOS.widgets.feedback', {});

    return {
      contentType: TYPE_TO_ACTIVITY_KEY.video,
      text: text || 'Was this video useful?',
      actions: sortByWeight(actions),
      show
    };
  }),
  branch(({ show }) => !show, renderNothing)
)(FeedbackBlock);
