import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../styles/components/page-header/secondary-header.module.scss';
import { Category, Image } from '../components';

const ImageSubBlock = ({ image, category, alt }) => (
  <div className={styles.image}>
    <Image src={image} alt={alt} className={styles['block-image']}>
      {category && <Category className={styles['category-sub-block']}>{category}</Category>}
    </Image>
  </div>
);

ImageSubBlock.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string,
  alt: PropTypes.string
};

ImageSubBlock.displayName = 'ImageSubBlock';

export { ImageSubBlock };
