import * as React from 'react';

export const CreditCard = ({ color = '#545454', size = 24 }) => (
  <svg width={size + 1} height={size} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M0.980957 0H24.981V24H0.980957V0Z' fill='white' fillOpacity='0.01' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.2793 4.74768H4.2793C3.1693 4.74768 2.2893 5.63768 2.2893 6.74768L2.2793 18.7477C2.2793 19.8577 3.1693 20.7477 4.2793 20.7477H20.2793C21.3893 20.7477 22.2793 19.8577 22.2793 18.7477V6.74768C22.2793 5.63768 21.3893 4.74768 20.2793 4.74768ZM20.2793 18.7477H4.2793V12.7477H20.2793V18.7477ZM20.2793 8.74768H4.2793V6.74768H20.2793V8.74768Z'
      fill={color}
    />
  </svg>
);
