import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/components/sharing-options-modal.module.scss';
import { Modal } from '../../modal';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { toggleModal } from '../../../store/reducers/modals';
import { insertVarIntoString } from '../../../helpers/insert-var-into-string';
import { Spacer } from '../spacers';

const SharingOptionsModalComponent = React.memo(({ show, toggleModal }) => {
  const onClose = () => toggleModal(false, 'sharing');

  const {
    SHARING: { buttons, title }
  } = window.CONFIG;

  return (
    <Modal title={title} show={show} onClose={onClose}>
      <Spacer renderAs='div' type='component' />
      {show && (
        <ul className={styles.grid}>
          {buttons.map(({ url, label, icon }) => (
            <SocialButton key={label} label={label} url={url} icon={icon} />
          ))}
        </ul>
      )}
      <Spacer renderAs='div' type='component' />
      <Clipboard show={show} />
    </Modal>
  );
});

export const SharingOptionsModal = connect(state => ({ show: state.modals.showSharing }), { toggleModal })(
  SharingOptionsModalComponent
);

const SocialButton = React.memo(({ label, icon, url }) => {
  const {
    SHARING: { message }
  } = window.CONFIG;

  return (
    <li className={styles['social-container']}>
      <a
        href={insertVarIntoString(url, { url: window.location.href, text: message })}
        className={styles.social}
        target='_blank'
        rel=' noopener noreferrer'
      >
        <span>{label}</span>
        <AffiliateIcon name={icon} />
      </a>
    </li>
  );
});

const Clipboard = React.memo(({ show }) => {
  const link = window.location.href;

  return (
    <div className={styles.clipboard}>
      <p className={styles.link}>{link}</p>
      <CopyButton show={show} link={link} />
    </div>
  );
});

const CopyButton = React.memo(({ link, show }) => {
  const [clicked, setClicked] = useState(false);
  const copy = () => {
    setClicked(true);
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    if (!show) {
      setClicked(false);
    }
  }, [show]);

  const {
    SHARING: { copyButtonLabel }
  } = window.CONFIG;

  return clicked ? (
    <div className={styles.copied}>
      <span>Copied!</span>
    </div>
  ) : (
    <button onClick={copy} className={styles.copy}>
      {copyButtonLabel}
    </button>
  );
});
