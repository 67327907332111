import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionTextareaInput = React.memo(
  ({
    label,
    userAttributeField,
    value,
    max,
    min,
    errorMessages,
    onChange,
    onError,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    placeholder,
    preSubmitButton,
    explainer,
    isRequired,
    isOptional
  }) => (
    <Input
      textarea
      inputType='text'
      placeholder={placeholder}
      label={label}
      name={userAttributeField}
      value={value}
      max={max}
      min={min}
      errorMessages={errorMessages}
      onError={onError}
      onChange={onChange}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      preSubmitButton={preSubmitButton}
      explainer={explainer}
      isRequired={isRequired}
      isOptional={isOptional}
    />
  )
);
