import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { ariaDisabledInterceptor } from '../../../../helpers/aria-disabled-interceptor';
import { ArticlesFeedback } from '../../../../services/article-feedback';
import { sortByWeight } from '../../../../helpers/array';
import { sendFeedback } from '../../../../store/reducers/content-interaction';
import { AffiliateIcon } from '../../../../components/icons/affiliate-icon';
import { TComponent } from '../../../../components/template';
import { getAdditionalDataByContentType } from '../../../../helpers/content-interaction';
import styles from '../../../../styles/templates/post-page/article-like.module.scss';

const FeedbackComponent = ({ actions, text, feedback, onSendFeedback, disabled }) => (
  <TComponent className={styles.block}>
    <p className={styles.heading}>{text}</p>
    <div className={styles.answers}>
      {actions.map(({ icon, label, ariaLabel, data }) => (
        <button
          key={data}
          className={styles.answer}
          onClick={ariaDisabledInterceptor(onSendFeedback(data))}
          aria-disabled={Boolean(disabled)}
          aria-label={ariaLabel}
        >
          <span className={styles.label}>{label}</span>
          <AffiliateIcon
            name={icon}
            className={classnames(styles.unanswered, {
              [styles.answered]: !!feedback,
              [styles.selected]: data === feedback
            })}
          />
        </button>
      ))}
    </div>
  </TComponent>
);

const mapStateToProps = ({ contentInteraction }, { contentKey }) => {
  const storeFeedback = contentInteraction.feedback[contentKey]?.activityStatusKey ?? ArticlesFeedback.get(contentKey);

  return { disabled: contentInteraction.loading, contentKey, storeFeedback };
};

const FeedbackBlock = compose(
  withRouter,
  connect(mapStateToProps, { sendFeedback }),
  // Added to select option without waiting for API response
  withState('feedback', 'setFeedback', ({ storeFeedback }) => storeFeedback),
  withProps(({ text, actions }) => ({
    text: text || 'Was it useful?',
    actions: sortByWeight(actions)
  })),
  withHandlers({
    onSendFeedback:
      ({ sendFeedback, setFeedback, contentKey, contentType, post = {} }) =>
      feedback =>
      () => {
        setFeedback(feedback);
        const data = getAdditionalDataByContentType({ contentType, key: contentKey, ...post });

        const options = {
          activityKey: contentType,
          activityStatusKey: feedback,
          data
        };
        sendFeedback(options);
      }
  }),
  lifecycle({
    componentDidUpdate() {
      const { feedback, setFeedback, storeFeedback } = this.props;

      if (feedback === null && storeFeedback) {
        setFeedback(storeFeedback);
      }
    }
  })
)(FeedbackComponent);

FeedbackBlock.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      data: PropTypes.string
    })
  ).isRequired,
  text: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf(['Article', 'story', 'video', 'blog']),
  contentKey: PropTypes.string.isRequired
};

export { FeedbackBlock };
