import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../../../../styles/widgets/carousel/custom-carousel.module.scss';
import { StoryBlock } from '../../../../pages/story-step/story-block';
import { StoryRead } from '../../../../pages/story-step/components/story-read';

export const StoryPage = React.memo(({ page, isLast, isActive, style, animationDirection, onStoryRead }) => {
  const { id, blocksToShow, ...rest } = page;

  return (
    <div
      id={id}
      role='group'
      style={style}
      aria-roledescription='story page'
      data-active={isActive}
      data-animation-direction={animationDirection}
      className={classnames(rest.theme, styles['carousel-item'])}
    >
      {/*This fake-href needs to make the story page focusable but do not navigate elsewhere and only reproduce its content announcement again.*/}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href='#' className={styles['carousel-item-link']}>
        {blocksToShow.map(([name, block]) => (
          <StoryBlock key={name} name={name} data={rest} {...block} />
        ))}
        {isLast && <StoryRead onStoryRead={onStoryRead} />}
      </a>
    </div>
  );
});

StoryPage.displayName = 'StoryPage';

StoryPage.propTypes = {
  page: PropTypes.object.isRequired,
  style: PropTypes.object,
  isLast: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSwiping: PropTypes.bool,
  animationDirection: PropTypes.oneOf(['prev', 'next']),
  onStoryRead: PropTypes.func
};
