import React from 'react';
import { Slot } from '../../components/slots';
import { PurifiedHtmlContainer } from '../../components/layout/purified-html-container';
import { FavoriteButton } from '../../components/layout/application-header/components/favorite-button';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import styles from '../../styles/templates/video-template.module.scss';
import { VideoFeedback } from './components/feedback';

export const VideoTemplateComponent = React.memo(({ video }) => (
  <>
    <Slot name='top-navigation-right'>
      <FavoriteButton slug={video.slug} ownKey={video.key} type={TYPE_TO_ACTIVITY_KEY.video} />
    </Slot>
    <div className={styles.body}>
      <div className={styles.video}>
        <PurifiedHtmlContainer
          parse
          html={video.url}
          className={styles.video}
          sanitizer={{
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['allow', 'height', 'width', 'allowfullscreen', 'frameborder']
          }}
        />
      </div>
      <VideoFeedback />
    </div>
  </>
));
