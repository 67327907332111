import { compose } from 'redux';
import { branch, componentFromProp, mapProps, renderNothing } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { HeaderComponent } from './components/header';
import { ImageComponent } from './components/image';
import { WordsComponent } from './components/words';
import { ButtonsComponent } from './components/buttons';
import { WidgetComponent } from './components/widget';

const blocks = {
  headerBlock: HeaderComponent,
  imageBlock: ImageComponent,
  wordsBlock: WordsComponent,
  buttonsBlock: ButtonsComponent,
  widgetBlock: WidgetComponent
};

export const StoryBlock = compose(
  mapProps(({ name, data, fields }) => {
    const { theme } = data;
    const blockProps = ObjectShim.pick(data, fields);

    return {
      theme,
      ...blockProps,
      component: blocks[name]
    };
  }),
  withMemoAndRef,
  branch(({ component }) => !component, renderNothing)
)(componentFromProp('component'));
