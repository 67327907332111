import React from 'react';
import PropTypes from 'prop-types';
import { LifeScoreDataviz } from '../../../life-score-dataviz';
import styles from '../../../../styles/components/page-header/secondary-header.module.scss';
import { Category } from '../components';
import { HeaderContent } from './header-content-container';

const LifeScoreSubBlock = ({ category }) => (
  <HeaderContent>
    <LifeScoreDataviz />
    {category && <Category className={styles['category-sub-block']}>{category}</Category>}
  </HeaderContent>
);

LifeScoreSubBlock.propTypes = {
  category: PropTypes.string
};

LifeScoreSubBlock.displayName = 'LifeScoreSubBlock';

export { LifeScoreSubBlock };
