import { POST_TYPES } from '../articles/article';

const CONTENT_TYPE_NAME = {
  article: POST_TYPES.ARTICLE,
  blog: POST_TYPES.BLOG,
  announcement: POST_TYPES.ANNOUNCEMENT,
  project: POST_TYPES.PROJECT,
  story: 'story',
  video: 'video',
  activity: 'activity'
};

export const TYPE_TO_ACTIVITY_KEY = {
  [CONTENT_TYPE_NAME.article]: 'Article',
  [CONTENT_TYPE_NAME.blog]: 'blog',
  [CONTENT_TYPE_NAME.announcement]: 'blog',
  [CONTENT_TYPE_NAME.project]: 'project',
  [CONTENT_TYPE_NAME.story]: 'story',
  [CONTENT_TYPE_NAME.video]: 'video'
};

export const TYPE_TO_ROUTE = {
  story: 'stories',
  project: 'projects',
  article: 'articles',
  video: 'videos',
  blog: 'blog',
  announcement: 'blog'
};

export const ROUTE_TO_TYPE = {
  [TYPE_TO_ROUTE.article]: CONTENT_TYPE_NAME.article,
  [TYPE_TO_ROUTE.blog]: CONTENT_TYPE_NAME.blog,
  [TYPE_TO_ROUTE.story]: CONTENT_TYPE_NAME.story
};

export const CONTENT_ROUTE_TO_STORE_KEY = {
  [CONTENT_TYPE_NAME.article]: 'contentPages',
  [CONTENT_TYPE_NAME.blog]: 'contentPages',
  [CONTENT_TYPE_NAME.story]: 'stories'
};
