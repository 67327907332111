import React from 'react';
import { connect } from 'react-redux';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { NativeAction } from '../../../services/native-action';
import { getNextActivity } from '../../../store/reducers/digital-coach';
import { sendStatus } from '../../../store/reducers/status-data';

export const RateAppButtonComponent = React.memo(
  ({ label, ariaLabel, getNextActivity, sendStatus, activityKey, statusKey, buttonType = 'major' }) => {
    const onClick = async () => {
      NativeAction.trigger('rate');
      await sendStatus(activityKey, statusKey);
      await getNextActivity();
    };

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const RateAppButton = connect(null, { getNextActivity, sendStatus })(RateAppButtonComponent);
