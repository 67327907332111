import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import isbot from 'isbot';
import { Column } from '../../components/layout/grid';
import styles from '../../styles/templates/status-page-template.module.scss';
import { getUser } from '../../store/reducers/account/get-user-action';
import { FHC_MODULE_STATUSES } from '../../helpers/constants';
import { withLoader } from '../../components/with-loader';
import { Header } from '../../components/layout/page-header';
import { DigitalCoachWidget } from '../../widgets/digital-coach-widget';
import { Template } from '../../components/template';
import { Spacer } from '../../components/layout/spacers';

const StatusPageComponent = ({ statuses, nextActivity, user }) => (
  <>
    <Header heading='Status Page' />
    <Template>
      <Spacer />
      <Column>
        <h3>LM External ID: {user.externalCode}</h3>
        <h3>LM Shadow ID: {user.userId}</h3>
        <h3>Introducer Code: {user.introducerCode}</h3>
        <h3>External Client ID: {user.clientCustomerId}</h3>
        <h3>External Scheme ID: {user.clientSchemeId}</h3>
        <h3>Session Device: {user.sessionDevice}</h3>
        <h3>Firebase token: {user.firebaseToken}</h3>
        <h3>userAgent: {window.navigator.userAgent}</h3>
        <h3>Is userAgent bot: {JSON.stringify(isbot(window.navigator.userAgent))}</h3>
      </Column>
      <Spacer />
      <Column>
        <DigitalCoachWidget />
      </Column>
      <Spacer />
      <Column>
        <h3>Next Activity: {nextActivity}</h3>
        <div className='body'>
          <table className={styles.table}>
            <tbody>
              <tr key='status-heading' className={styles.heading}>
                <td>Activity</td>
                <td>Status</td>
                <td>Date and Time</td>
              </tr>
              {Object.keys(statuses).map(key => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{statuses[key].status.key}</td>
                  <td>{statuses[key].dateTime || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Column>
      <Spacer />
    </Template>
  </>
);

const mapStateToProps = state => ({
  loading: state.statusData.loading,
  statuses: state.statusData.statuses
});

export const StatusPage = compose(
  connect(mapStateToProps, { getUser }),
  withState('nextActivity', 'setNextActivity', null),
  withState('user', 'setUser', {}),
  lifecycle({
    async componentDidMount() {
      const { setNextActivity, statuses, getUser, setUser } = this.props;
      setUser(await getUser());
      for (const activityKey of Object.keys(statuses)) {
        if (
          ![
            FHC_MODULE_STATUSES.NOT_APPLICABLE,
            FHC_MODULE_STATUSES.ALREADY_HAVE_ONE,
            FHC_MODULE_STATUSES.COMPLETED,
            FHC_MODULE_STATUSES.NOT_KEEN
          ].includes(statuses[activityKey].status.key)
        ) {
          setNextActivity(activityKey);
          break;
        }
      }
    }
  }),
  withLoader
)(StatusPageComponent);
