import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router';
import { FeedbackBlock } from '../../../../templates/post-page/blocks/article-like';
import { sortByWeight } from '../../../../helpers/array';
import { ConfigService } from '../../../../services/config-service';
import { sendFeedback } from '../../../../store/reducers/content-interaction';
import { TYPE_TO_ACTIVITY_KEY } from '../../../../helpers/content-interaction/constants';

const mapStateToProps = ({ content, contentInteraction }, { match }) => {
  const contentKey = content.stories[match.params.slug]?.key ?? '';

  return { contentKey };
};

export const StoryFeedback = compose(
  withRouter,
  connect(mapStateToProps, { sendFeedback }),
  withProps(({ feedbacks, match, storyKey }) => {
    const { text, actions } = ConfigService.get('STORIES.widgets.storyFeedback', {});

    return {
      contentType: TYPE_TO_ACTIVITY_KEY.story,
      text: text || 'Was this story useful?',
      actions: sortByWeight(actions)
    };
  })
)(FeedbackBlock);
