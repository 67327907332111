import { isNil } from '@packages/helpers/core/common';

export const isStatus = status => {
  return !isNil(status) && ['key', 'order'].every(prop => status.hasOwnProperty(prop));
};

export const parseStatuses = statuses => {
  const result = {};

  for (let [key, { status, dateTime }] of Object.entries(statuses)) {
    if (isStatus(status)) {
      result[key] = {
        key: status.key,
        order: status.order,
        dateTime: dateTime ?? null
      };
    } else {
      throw Error(`Every status should have next fields: 'key', 'order'`);
    }
  }

  return result;
};

export const isValid = (activity, status, statuses) => {
  return statuses.hasOwnProperty(activity) && isStatus(status);
};

export const hasHigherOrder = (activity, status, statuses) => {
  const prevStatus = statuses[activity];

  if (!isStatus(prevStatus) || isNil(status.order)) {
    return true;
  }

  return status.key !== prevStatus.key && status.order >= prevStatus.order;
};

export const areStatusesEqual = (activity, status, statuses) => {
  const prevStatus = statuses[activity];

  if (!isStatus(prevStatus) || isNil(status.order)) {
    return false;
  }

  return status.key === prevStatus.key;
};

/**
 * @param {object} action
 * @param {object} action.params
 * @param {object} params.data
 * @param {string} params.data.key
 * @param {object} action.payload
 * @param {string} payload.activityKey
 * @param {string} payload.activityStatusKey
 * @return {{activityKey: string, key: string, activityStatusKey: string}}
 */
export const savedItemFromAction = ({ params, payload }) => {
  const { key } = params.data;
  const { activityKey, activityStatusKey } = payload;

  return { key, activityKey, activityStatusKey };
};

/**
 * @param {string} activityKey
 * @param {string} activityStatusKey
 * @param {object} data
 * @param {string} data.key - a.k. slug
 * @return {`activityKey=${string}&activityStatusKey=${string}&data=${string}`} delete query params
 */
export const buildQueryParams = ({ activityKey, activityStatusKey, data }) =>
  `activityKey=${activityKey}&activityStatusKey=${activityStatusKey}&data=${encodeURIComponent(JSON.stringify(data))}`;

export const parseStatusesToSingleDimension = statuses => {
  const result = {};

  for (let [key, { status }] of Object.entries(statuses)) {
    if (isStatus(status)) {
      result[key] = status.key;
    } else {
      throw Error(`Every status should have next fields: 'key', 'order'`);
    }
  }

  return result;
};
