import React from 'react';
import { connect } from 'react-redux';
import { getNextActivity, setRefreshPageBackLink } from '../../../store/reducers/digital-coach';
import { updateUserMarketingPreferences } from '../../../store/reducers/account/actions';
import { digitalCoach as digitalCoachAPI } from '../../../api/digital-coach';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';

const SnoozeButtonComponent = React.memo(
  ({ getNextActivity, label, ariaLabel, callUrl, body, buttonType = 'major' }) => {
    const onClick = async () => {
      await digitalCoachAPI.callCustomPostAction(callUrl, body);
      getNextActivity();
    };

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const SnoozeButton = connect(null, { getNextActivity, updateUserMarketingPreferences, setRefreshPageBackLink })(
  SnoozeButtonComponent
);
