import React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { compose } from 'recompose';
import { PERCENTAGE_MASK } from '../../helpers/formatters/money-format';
import styles from '../../styles/components/input.module.scss';
import { formatDisplayValue, formatAfterChangeValue } from '../../helpers/inputs';
import { omitProps } from '../../helpers/hocs/omit-props';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from './input-field';

const InputComponent = React.memo(
  ({ value, type, onChange, format, className, error, isRequired, explainer, ariaValue, ...props }) => {
    let pattern = '[0-9]*';
    const additionalProps = { ...props };

    const handleOnChange = e => {
      const value = format ? formatAfterChangeValue(e.target.value, format, type) : e.target.value;

      onChange(value);
    };

    if (type === 'text' || type === 'email' || type === 'password') {
      pattern = null;
    }

    if (error) {
      additionalProps['aria-errormessage'] = `${props.id}_error`;
    }

    if (explainer) {
      additionalProps['aria-describedby'] = `${props.id}_explainer`;
    }

    if (format === 'percentage') {
      additionalProps.mask = PERCENTAGE_MASK;
      additionalProps.maskChar = ' ';
    }

    return (
      <InputMask
        type={type}
        value={formatDisplayValue(value, format)}
        aria-valuetext={ariaValue}
        className={classnames(styles.field, className)}
        pattern={pattern}
        autoComplete='off'
        onChange={handleOnChange}
        aria-invalid={!!error}
        aria-required={isRequired}
        {...additionalProps}
      />
    );
  }
);

const CONSTRAINTS = ['buttonLabel'];

export const Input = omitProps(CONSTRAINTS)(InputComponent);

export const withInputLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputField = compose(withAnchor, withContainer, withExplainer, withError, withInputLabel)(Input);
