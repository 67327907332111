import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderComponent, withHandlers, withState } from 'recompose';
import { getUser } from '../../../store/reducers/account/get-user-action';
import { InputWithButton as Input } from './components/input-with-button';
import { QuestionButtonInput } from './button';

const Component = React.memo(
  ({
    label,
    format,
    value,
    max,
    min,
    errorMessages,
    onInputChange,
    onError,
    onInputSubmit,
    loading,
    disabled,
    submitError,
    buttonLabel,
    buttonType,
    placeholder,
    preSubmitButton,
    explainer,
    isRequired
  }) => (
    <Input
      type='text'
      placeholder={placeholder}
      inputType='text'
      label={label}
      format={format}
      value={value}
      max={max}
      min={min}
      submitError={submitError}
      errorMessages={errorMessages}
      onChange={onInputChange}
      onError={onError}
      onSubmit={onInputSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      preSubmitButton={preSubmitButton}
      explainer={explainer}
      isRequired={isRequired}
    />
  )
);

const IntroducerButton = compose(
  withHandlers({
    onSubmit: ({ setSubmitError, errorMessages, onSubmit, userAttributeValue }) =>
      onSubmit({ setSubmitError, errorMessages, value: userAttributeValue })
  })
)(QuestionButtonInput);

export const QuestionIntroducerInput = compose(
  connect(null, { getUser }),
  withState('submitError', 'setSubmitError', ''),
  withHandlers({
    onInputChange: ({ setSubmitError, errorMessages, onChange }) => onChange({ setSubmitError, errorMessages }),
    onInputSubmit: ({ setSubmitError, errorMessages, onSubmit, value }) =>
      onSubmit({ setSubmitError, errorMessages, value })
  }),
  branch(({ userAttributeValue }) => userAttributeValue, renderComponent(IntroducerButton)),
  lifecycle({
    async componentDidMount() {
      const { getUser, onInputChange, displayIntroducer, setSubmitError } = this.props;

      if (displayIntroducer) {
        try {
          const { introducerCode } = await getUser();

          onInputChange(introducerCode);
        } catch (e) {
          setSubmitError(e.message);
        }
      } else {
        onInputChange();
      }
    }
  })
)(Component);
