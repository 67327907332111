import React from 'react';
import PropTypes from 'prop-types';
import { AccordionItem, useAccordionState } from '../../../components/accordion';
import { ConfigService } from '../../../services/config-service';
import { KnowledgeBaseItem } from '../item';
import { TopicHeader } from './topic-header';

const ACCORDION_NAME = 'knowledge-base-topic';

export const AccordionItemsWrapper = React.memo(({ children }) => <ul>{children}</ul>);

const CollapsibleTopic = React.memo(({ category, subHeading, slug, initialState, posts = [] }) => {
  const {
    TEMPLATE: { topicIcons, list, statusLabels = {} }
  } = ConfigService.get('ARTICLES_HOME_PAGE', {});

  const [state, toggle] = useAccordionState(ACCORDION_NAME, { [category]: initialState[category] });

  const handleClick = key => () => {
    toggle(key);
  };

  return (
    <AccordionItem
      renderWrapperAs='section'
      expanded={state[category]}
      onClick={handleClick(category)}
      title={<TopicHeader topicIcons={topicIcons} category={category} subHeading={subHeading} slug={slug} />}
    >
      <AccordionItemsWrapper>
        {posts.map(post => (
          <KnowledgeBaseItem
            key={post.id}
            item={post}
            showThumbnailImage={list?.showThumbnailImage}
            showStatusLabel={list?.showStatusLabel}
            showStatusIcon={list?.showStatusIcon}
            statusLabels={statusLabels}
          />
        ))}
      </AccordionItemsWrapper>
    </AccordionItem>
  );
});

CollapsibleTopic.propTypes = {
  category: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  slug: PropTypes.string,
  initialState: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool
  }),
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      thumbnail: PropTypes.string
    })
  )
};

CollapsibleTopic.displayName = 'CollapsibleTopic';

export { CollapsibleTopic };
