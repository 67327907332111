import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { getVideo } from '../../store/reducers/content';
import { withLoader } from '../../components/with-loader';
import { VideoTemplateComponent } from './component';

const mapStateToProps = ({ content }, { match }) => {
  const video = content.videos[match.params.slug];
  const loading = content.loadingVideos;

  return {
    video,
    loading
  };
};

export const VideoTemplate = compose(
  connect(mapStateToProps, { getVideo }),
  lifecycle({
    async componentDidMount() {
      const { video, match, getVideo } = this.props;

      if (!video) {
        await getVideo(match.params.slug);
      }
    }
  }),
  withLoader,
  branch(({ video }) => !video, renderNothing)
)(VideoTemplateComponent);
