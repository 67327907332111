import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ConfigService } from '../../services/config-service';
import { getCategories } from '../feed';
import { DEFAULT_CATEGORY_KEY } from '../feed/constants';

export const CONTENT_HOME_PAGE_TYPE = {
  BLOGS: 'blogs',
  ARTICLES: 'articles'
};

/**
 * Category config item.
 * @typedef {Object} CategoryConfigItem
 * @property {number} weight - Weight
 * @property {string} label - Label
 * @property {string} value - Key
 */

/**
 * Gather configuration information from admins config file for specific content home pages
 * @param type {string} - the home page type
 * @return {Array.<CategoryConfigItem> | null} - if no category found in config - return null
 */

export const getCategoriesByHomePageType = type => {
  switch (type) {
    case CONTENT_HOME_PAGE_TYPE.BLOGS:
      return ConfigService.get('BLOGS_HOME_PAGE.TEMPLATE.filter.categories', null);
    case CONTENT_HOME_PAGE_TYPE.ARTICLES:
    default:
      return ConfigService.get('ARTICLES_HOME_PAGE.TEMPLATE.filter.categories', null);
  }
};

/**
 * Properties list.
 * @typedef {Object} FilterProperties
 * @property {Array} postsList - list of posts from WP
 * @property {string} accessor - the key name, where the category name is stored in postsList item
 */

/**
 * Pick prop that contains the list of posts and accessor for specific content home page
 * @param pageType {string} - the home page type
 * @param props {Object} - list of available props
 * @return {FilterProperties} - picked props
 */

export const pickPropsByHomePageType = (pageType, props) => {
  switch (pageType) {
    case CONTENT_HOME_PAGE_TYPE.BLOGS:
      return { postsList: props.content, accessor: 'subCategory' };
    case CONTENT_HOME_PAGE_TYPE.ARTICLES:
      return { postsList: props.knowledgeBase, accessor: 'category' };
    default:
      return {};
  }
};

/**
 * Creates a plain array of existing categories in items (posts)
 * @param postsList {Array} - list of posts to display
 * @param accessor {string} - the key name, where the category name is stored in postsList item
 * @return {Array.<String>} - list of categories
 */

export const getSortedCategoriesFromPostsList = ({ items, accessor }) => {
  const uniqueCategories = new Set(
    items.flatMap(item => {
      if (!item) {
        return DEFAULT_CATEGORY_KEY;
      }

      const key = FunctionShim.isFunction(accessor) ? accessor(item) : accessor;

      return item?.[key] ?? DEFAULT_CATEGORY_KEY;
    })
  );

  return [...uniqueCategories].sort();
};

/**
 * Transforms plain categories into category options.
 * @param { Array.<String>  } postCategories
 * @returns { Array.<CategoryOption> } array of category options
 */
export const plainCategoriesToCategoryOptionsList = postCategories => {
  const formattedPostsCategories = postCategories.map((item, index) => ({
    label: item,
    value: item,
    weight: index + 1
  }));

  return getCategories(formattedPostsCategories);
};

/**
 * Takes an existing list of posts (blogs, articles),and exclude from it items, not in the config categories list
 * @param postsList {Array} - list of posts to display
 * @param configCategories {Array.<CategoryConfigItem>} - list of config categories
 * @param accessor {string} - the key name, where the category name is stored in postsList item
 * @return {Array} - filtered postsList
 */
export const excludeUnsetConfigCategories = ({ postsList, configCategories, accessor }) => {
  return configCategories.reduce((acc, current) => {
    const filteredList = postsList.filter(item => {
      if (Array.isArray(item[accessor])) {
        return item[accessor].includes(current.value);
      }

      return item[accessor] === current.value;
    });

    if (filteredList) {
      acc.push(...filteredList);
    }

    return acc;
  }, []);
};
